module Enum {
    export enum EnumPageLookupMode {

    }

    export enum EnumOrderByType {
        Ascending = 0,
        Descending
    }

    export enum EnumSystemType {
        None = 123,
        SARSCases = 124,
        EV2ExportConsignment = 125,
        EV2BondMovement = 126,
        EV2DutyDrawback = 127,
        EV2Allocation = 128,
        EV2Load = 129,
        PurchaseOrder = 130,
        Product = 131,
        ProductTariffing = 132,
        Manifest = 211,
        DeliveryInstruction = 239,
        LetterOfCredit = 306,
        CommercialInvoice = 411,
        GoodsDeclaration = 423,
        Consignment = 428,
        ForexContract = 451,
        SalesOrder = 460,
        SupplierPayment = 515,
        Debtor = 582,
        CustomerPayments = 588,
        Law = 673,
        Permit = 735,
        RefundClaim = 756,
        Company = 758,
        ImportConsignment = 797
    }

    export enum ClassificationEnum {
        CountryTariffBook = 1,
        SystemCategory = 2,
        TariffAmendmentType = 3,
        TariffAmendmentStatus = 4,
        TariffAmendmentRecordType = 5,
        TariffDutyType = 6,
        TariffActivityType = 7,
        ImportFromType = 8,
        ImportFileType = 9,
        HsCodeType = 11,
        FullTextSearchType = 14,
        CompanyStatus = 16,
        CompanyType = 18,
        ProductStatus = 19,
        ProductTariffCountryStatus = 20,
        CommunicationType = 23,
        TariffQuestionType = 25,
        IntegrationTransportType = 26,
        CommunicationWebServiceAuthentication = 27,
        IntegrationInterfaceDirection = 28,
        CommunicationFtpProtocol = 29,
        ScheduleType = 30,
        ScheduleFrequency = 31,
        ScheduleDailyFrequency = 32,
        ScheduleDailyTimeMeasure = 33,
        CommunicationDirection = 34,
        DataElementType = 35,
        StatusType = 38,
        StatusLogic = 39,
        PaymentDueDateType = 40,
        RateType = 43,
        CounterType = 44,
        TariffingOption = 45,
        SystemType = 46,
        SoapVersion = 47,
        CustomAttribute = 48,
        SarsCaseVerdict = 49,
        SarsCaseStatus = 50,
        ShippingPriorityType = 51,
        TariffAmendmentDataType = 52,
        TariffCodeType = 53,
        TariffAmendmentRestrictionType = 54,
        ManifestType = 55,
        ManifestStatus = 56,
        ContainerType = 57,
        ContainerStatus = 58,
        ContainerSealingParty = 59,
        PackingGroup = 60,
        VolumeUnitOfMeasure = 61,
        CostingModelSourceType = 62,
        CostingModelFormula = 63,
        CostingModelType = 64,
        CalendarType = 65,
        DocumentRepositoryStatus = 66,
        PurchaseOrderLineType = 68,
        ManifestCargoStatusIndicator = 69,
        DeclarationType = 70,
        TemplateType = 71,
        TemplateLineType = 72,
        EdiMessageType = 73,
        ManifestDocumentType = 74,
        ManifestGender = 75,
        ManifestOccupation = 76,
        ManifestReasonforMovement = 77,
        ManifestTravelerType = 78,
        CostBy = 79,
        //CostingModelExchangeRateType = 80, - Use RateType (43) instead
        CostingModelParametersType = 81,
        IncotermType = 82,
        AmountToleranceDirection = 83,
        CreditType = 84,
        ProductDocumentType = 85,
        InsuranceParty = 86,
        BankCharges = 87,
        DocumentDeliveryMethod = 88,
        LetterOfCreditPaymentTerms = 89,
        LetterOfCreditAdviceMethod = 90,
        CertificateOfOriginIssuer = 91,
        TransportDocumentType = 92,
        LetterOfCreditOriginalDocumentDeliveryMethod = 93,
        StatusAuthorisationType = 94,
        ServiceScheduledTask = 95,
        RejectionStatusType = 96,
        LCDiscrepancyType = 97,
        LCDiscrepancyAction = 98,
        InsuranceIsEffected = 99,
        LetterOfCreditConfirmation = 100,
        CustomFunction = 101,
        UnregisteredTraderType = 102,
        RelatedParty = 103,
        CustomsStatisticalUnitType = 104,
        CustomsApportionment = 105,
        ForexContractType = 106,
        ForexContactMaturity = 107,
        ForeignExchangeControlType = 108,
        EDIStatusIndicator = 109,
        CPCType = 110,
        CustomsAccountDetailType = 111,
        SurrenderingType = 112,
        FECType = 114,
        InvoiceCreditType = 115,
        SundryItemBillingType = 117,
        DocumentActionType = 118,
        ForexContractSetting = 119,
        CTOType = 120,
        CTOWharfagePayable = 121,
        CTORailage = 122,
        BankAccountType = 124,
        CertificateRegisterDocumentType = 127,
        CertificateRegisterStatus = 128,
        CertificateStatus = 129,
        FlowType = 130,
        ComplianceDateType = 131,
        ComplianceTemplateType = 132,
        ComplianceStatus = 133,
        GuaranteeType = 134,
        DebtorsTransactionType = 135,
        DebtorsCreditCheck = 136,
        ExternalEntity = 137,
        CustomerPaymentsType = 138,
        CostInvoiceStatus = 139,
        DocumentTransactionType = 140,
        CommunicationEmailProtocol = 143,
        InvoiceReconTransactionType = 144,
        SOLASMethod = 152,
        TariffAmendmentRestrictionStatus = 154,
        SundryCostingApportion = 156,
        SealType = 160,
        GoodsDeclarationChangeInitiatedBy = 169,
        RefundDeclarantCapacity = 170,
    }

    export enum EnumCounterTypes {
        PurchaseOrder = 113,
        Manifest = 205,
        Waybill = 206,
        LetterOfCredit = 297,
        Consignment = 383,
        CommercialInvoice = 399,
        ForexContract = 421,
        SalesOrder = 454,
        SupplierPayment = 512,
        CustomerPayment = 580,
        DutyDrawback = 667,
        VOCRequest = 682,
        Custom = 692,
        ImportConignment = 830,
        ImportCommercialInvoice = 831
    }

    export enum EnumCostModelType {
        ELC = 222,
        GLC = 244,
        ELCPreCosting = 413,
        ExportCosting = 461,
        ExportConsignment = 600
    }

    export enum EnumStatusType {
        POStatus = 97,
        POShippingStatus = 98,
        LetterOfCredit = 304,
        ConsignmentImport = 352,
        CommercialInvoice = 407,
        ForexContract = 424,
        ConsignmentExport = 432,
        SalesOrder = 459,
        SupplierPayment = 510,
        CustomerPayment = 589,
        CostInvoice = 625,
        DutyDrawback = 153,
        VOCRequest = 155,
        Permit = 741,
        RefundClaim = 752
    }

    export enum EnumStatusAuthorisationType {
        LetterOfCredit = 348,
        PurchaseOrder = 349,
        DeliveryInstruction = 370,
        ConsignmentImport = 371,
        CostInvoice = 410,
        ForexContract = 422,
        ConsignmentExport = 433,
        SalesOrder = 465,
        SupplierPayment = 511,
        CustomerPayment = 590
    }

    export enum EnumTariffCodeType {
        NationMeasure = 163,
        ProcedureMeasure = 164,
        AdditionalCode = 165
    }
    
    export enum EnumCustomsApportionment {
        Value = 401,
        OrderQuantity = 402,
        PriceQuantity = 403,
        UnitOfMeasure = 404
    }
    
    export enum EnumShippingPriorityType {
        PurchaseOrder = 153,
        Manifest = 154
    }

    export enum EnumCalendarType {
        MonthEndDates = 225,
        TradingCalendar = 226,
        ShippingWeeks = 227,
        Custom = 230
    }

    export enum EnumLockdownType {
        None = 0,
        Partial = 1,
        Authorised = 2,
        Finalised = 3,
        Cancelled = 4,
        Full = 5
    }

    export enum EnumModelType {
        None = 0,
        PurchaseOrder = 1,
        DeliveryInstruction = 2,
        LetterOfCredit = 3,
        TariffAmendment = 4,
        GoodsDeclaration = 5,
        ExportConsignment = 6,
        ElcPreCosting = 7,
        ExportCommercialInvoice = 8,
        CustomsAccount = 9,
        ForexContract = 10,
        SalesOrder = 12,
        SupplierPayment = 13,
        ConsignmentCompliance = 33,
        Manifest = 34,
        DebtorsAccount = 35,
        CustomerPayment = 36,
        CostInvoice = 37,
        DebtorTransaction = 38,
        ImportConsignment = 39,
        ImportCommercialInvoice = 40,
        Permit = 41,
        Refund = 42
    }

    export enum EnumMessageType {
        Error = 1,
        Warning = 2,
        Success = 3,
        Authorisation = 4
    }

    export enum CustomsRecon {
        CustomsStatus = 142,
        RefundClaimStatus = 752
    }

    export enum ProductTariffStatus {
        Inactive = 40,
        Draft = 41, 
        AwaitingApproval = 42,
        Active = 43,
        AwaitingSampling = 44
    }

    export enum EnumManifestStatus {
        Planned = 176,
        Confirmed = 177,
        SentToCustoms = 178,
        ApprovedByCustoms = 179,
        RejectedByCustoms = 180,
        //Loaded = 181,
        ReleasedByCustoms = 182,
        Closed = 183,
        Cancelled = 184,
        CancelledAtSars = 185,
        PendingCancelledAtSars = 235,
        RequestClearing = 559,
        /*MJN | Added 2015-09-24 | SARS CARN Update included two new Statuses*/
        GateIn = 623,
        GateOut = 624,
        ReadyforDispatch = 675,
        Dispatch = 676,
        CIAcknowledged = 707,
        Arrived = 708,
        Delivered = 709

    }

    export enum EnumCustomsPartyType {
        Agent = 1,
        Declarant = 2,
        Importer = 3,
        Exporter = 4,
        Owner = 5,
        ProcessingParty = 6,
        Guarantor = 7,
    }


    export enum EnumReportParameterType {
        Text = 0,
        Numeric = 1,
        Date = 2,
        DropDown = 3,
        MultiSelect = 4
    }

    export enum EnumPRIndicator {
        /// <summary>
        /// P&R check has not been run or countries not setup for P&R Check.
        /// </summary>
        Unknown = 0,
        /// <summary>
        /// P&R has run and no prohibited or restricted icons.
        /// </summary>
        Green = 1,
        /// <summary>
        /// There are P&R items but none are prohibited and there are permits for all restricted items.
        /// </summary>
        Warning = 2,
        /// <summary>
        /// There are prohibited items or restricted items with no permits.
        /// </summary>
        Error = 3
    }
}